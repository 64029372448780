// import { useLocation } from "@reach/router"
import axios from "axios"
import { graphql, Link, navigate, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import { parse as queryParse } from "query-string"
import React, { useEffect, useState } from "react"
import Buttons from "../components/common/button"
import { setHubspotutkCookie } from "../components/common/FormBuilder/Form-Function"
import Frame from "../components/common/frame"
import SVGIcon from "../components/common/SVGIcon"
// import YoutubePlayer from "../components/common/Youtube-player"
import YoutubePlayer from "../components/common/Youtube-player"
import Navigation from "../components/navigation"
import { BrowserDetails } from "../components/utility/browserDetails"
import { RenderIf } from "../components/utility/RenderIf"
// import Slide from "../components/utility/slide_content"
import { errors as ErrorMessages } from "../constants/errorMessages"
import "../styles/pages/thankyou.scss"

function ThankYou({ location }) {
  const [param, setParam] = useState("null")
  const [userDetails, setUserDetails] = useState(null)
  const [showVideo, setShowVideo] = useState(false)
  // const location = useLocation()
  const searchParams = queryParse(location.search)

  useEffect(() => {
    if (location?.state?.showVideo) {
      setShowVideo(true)
    }

    if (searchParams.src) {
      setParam(searchParams.src)
    } else {
      setParam("others")
    }
  }, [searchParams.src])

  // DEmo signup

  const [disableButton, setDisableButton] = useState()
  const [IpStack, setIpStack] = useState({})
  const [countryPhoneCode, setCountryPhoneCode] = useState(null)
  const [defaultCountryCode, setDefaultCountryCode] = useState("us")
  const [humioLogAvailable, setHumioLogAvailable] = useState(false)
  const [isAPILoading, setIsAPILoading] = useState(false)
  const [apiphoneNumberCountryCode, setapiphoneNumberCountryCode] = useState("")
  const [signupError, setSignupError] = useState(false)

  const checkHumioLogger = () => {
    if (window.logger) {
      console.log("Humio logger available")
      !humioLogAvailable && setHumioLogAvailable(true)
    } else {
      console.log("Humio logger not-available")
      humioLogAvailable && setHumioLogAvailable(false)
    }
  }

  function checkCurrencySupport(currencyCode) {
    const supported_currencies = [
      "AED",
      "AUD",
      "BHD",
      "CAD",
      "EUR",
      "NZD",
      "QAR",
      "SAR",
      "SGD",
      "USD",
      "ZAR",
      "GBP",
    ]

    return supported_currencies.includes(currencyCode) ? currencyCode : "USD"
  }

  useEffect(() => {
    let checkIsReferral = sessionStorage.getItem("sopsreff")
    checkHumioLogger()
    axios.get(process.env.IP_STACK_API).then(function(response) {
      setIpStack(response.data)
      const cPhoneCode = response.data.location.calling_code
      setCountryPhoneCode(
        cPhoneCode.includes(",") ? cPhoneCode.split(",")[0] : cPhoneCode
      )
      setDefaultCountryCode(response.data.country_code.toLowerCase())
    })

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        var script = document.createElement("script")
        script.type = "text/javascript"
        script.src = url
        script.id = id
        script.onload = function() {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.CAPTCHA_KEY}`,
      function() {
        // console.log("Script loaded!")
      }
    )
  }, [])

  function postRequest(url, data, callback, withCredentials) {
    var xhr = new XMLHttpRequest()

    xhr.open("POST", url)

    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.withCredentials = withCredentials ? true : false

    xhr.send(data)

    xhr.onreadystatechange = () => callback(xhr)
  }

  const sendStatusHumioLog = (data, responseText, status) => {
    let log_data_success = {
      finalData: data,
      response: responseText,
    }

    //checks if humio logger is available before pushing
    checkHumioLogger()

    humioLogAvailable &&
      (ErrorMessages[status]
        ? window.logger.info(ErrorMessages[status].loggerInfo, {
            log_data_success,
          })
        : window.logger.info(ErrorMessages.UNKNOWN.loggerInfo, {
            log_data_success,
            responseStatus: status,
          }))
    humioLogAvailable && window.logger.flush()
  }

  const sendEventHumioLog = (data, responseText, message) => {
    let log_data = {
      finalData: data,
      response: responseText,
    }

    //checks if humio logger is available before pushing
    checkHumioLogger()

    humioLogAvailable &&
      window.logger.info(message, {
        log_data,
      })
    humioLogAvailable && window.logger.flush()
  }

  //handles response from Hubspot
  const handleXHRResult = (xhr, token, data) => {
    if (
      xhr.status === 200 ||
      JSON.parse(xhr.response).errors[0].errorType !== "INVALID_EMAIL"
    ) {
      xhr.status === 200
        ? sendEventHumioLog(
            data,
            xhr.responseText,
            "Hubspot Lead after response"
          )
        : sendEventHumioLog(
            data,
            xhr.responseText,
            "Hubspot Response Failure but valid Email"
          )
    } else {
      setDisableButton(false)

      //changes signup button back to default state
      setIsAPILoading(false)

      sendEventHumioLog(
        data,
        xhr.responseText,
        "Hubspot Response Failure, with Invalid Email Error"
      )
    }
  }

  //handles reponse from Signup API
  const handleXHRPResult = (xhrp, final_datap, data) => {
    if (xhrp.readyState === 4) {
      var objp = JSON.parse(xhrp.responseText)
      // objp["status"] !== "IN_SESSION" && sendStatusHumioLog(final_datap, xhrp.responseText, objp["status"])
      sendStatusHumioLog(final_datap, xhrp.responseText, objp["status"])

      data.fields.push({
        name: "signup_info",
        value: `Humio: ${
          window.logger ? "Humio available" : "Humio not Available"
        } , status : ${objp["status"]}, response : ${
          xhrp.responseText
        }, hubspot-cookie : ${
          setHubspotutkCookie("hubspotutk") ? "available" : "not available"
        }`,
      })
      var final_response_data = JSON.stringify(data)

      postRequest(
        process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT,
        final_response_data,
        function() {
          return
        }
      )

      //if below checks are valid redirects to received subDomain
      if (objp["status"] === "SUCCESS" || objp["status"] === "IN_SESSION") {
        !isAPILoading && setIsAPILoading(true)
        window.location.href = `/signup/thank-you?src=${objp["subDomainName"]}`
      } else {
        //changes signup button back to default state
        // setSignupError(true)
        setIsAPILoading(false)
        setDisableButton(false)
      }
    }
  }

  const checkIfObjectEmpty = obj => {
    if (obj !== null) {
      const isEmpty = Object.keys(obj).filter((key, i) => obj[key] === null)
      return isEmpty.length > 0
    }
    return true
  }

  const checkIfSessionEmpty = () => {
    let sessiondata = {
      emailId: sessionStorage.getItem("email"),

      firstName: sessionStorage.getItem("firstname"),

      lastName: sessionStorage.getItem("lastname"),

      phoneNumber: sessionStorage.getItem("mobilephone"),

      companyName: sessionStorage.getItem("company"),
    }
    return checkIfObjectEmpty(sessiondata)
  }

  const DemoSignup = () => {
    if (!checkIfSessionEmpty()) {
      checkHumioLogger()
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.CAPTCHA_KEY, { action: "submit" })
          .then(token => {
            setIsAPILoading(true)
            //data object for Hubspot form
            var data = {
              fields: [
                {
                  name: "firstname",
                  value: sessionStorage?.getItem("firstname"),
                },
                {
                  name: "lastname",
                  value: sessionStorage?.getItem("lastname"),
                },
                {
                  name: "email",
                  value: sessionStorage?.getItem("email"),
                },
                {
                  name: "company",
                  value: sessionStorage?.getItem("company"),
                },
                {
                  name: "mobilephone",
                  value: sessionStorage?.getItem("mobilephone"),
                },
                {
                  name: "signup_info",
                  value: `${
                    window.logger ? "Humio available" : "Humio not Available"
                  },  hubspot-cookie : ${
                    setHubspotutkCookie("hubspotutk")
                      ? "available"
                      : "not available"
                  }, Browser: ${BrowserDetails.browserName}, Browser version: ${
                    BrowserDetails.fullVersion
                  }, OS : ${BrowserDetails.OSName}, Device type: ${
                    BrowserDetails.deviceType
                  }, Device resolution: ${BrowserDetails.resolution}`,
                },
                {
                  name: "continent",
                  value: IpStack?.continent_name,
                },
                {
                  name: "continent_code",
                  value: IpStack?.continent_code,
                },
                {
                  name: "country_code",
                  value: IpStack?.country_code,
                },
                {
                  name: "country",
                  value: IpStack?.country_name,
                },
                {
                  name: "state",
                  value: IpStack?.region_name,
                },
                {
                  name: "city",
                  value: IpStack?.city,
                },
                {
                  name: "zip",
                  value: IpStack?.zip,
                },
                {
                  name: "country_phone_code",
                  value: countryPhoneCode,
                },
                {
                  name: "sopsreferral",
                  value: sessionStorage.getItem("sopsreff"),
                },
              ],
              context: {
                hutk: setHubspotutkCookie("hubspotutk"),
                pageUri: document.URL,
                pageName: "Demo thankyou signup",
              },
            }
            var final_data = JSON.stringify(data)
            //data object for Signup API
            var datap = {
              emailId: sessionStorage.getItem("email"),
              firstName: sessionStorage.getItem("firstname"),
              lastName: sessionStorage.getItem("lastname"),
              phoneNumber: sessionStorage.getItem("mobilephone"),
              companyName: sessionStorage.getItem("company"),
              captchaToken: token,
              mspTimeZone: IpStack?.time_zone?.id || null,
              phoneNumberCountryCode: apiphoneNumberCountryCode,
              countryCode: IpStack?.country_code || null,
              currencyCode: checkCurrencySupport(IpStack?.currency?.code),
            }
            var final_datap = JSON.stringify(datap)
            console.log("Product Api call", final_datap)
            //logs signup send event
            sendEventHumioLog(final_datap, "Before hitting signup API")
            //post request for Signup
            postRequest(
              process.env.PRODUCT_SIGNUP_API,
              final_datap,
              function(xhrp) {
                handleXHRPResult(xhrp, final_datap, data)
              },
              true
            )
            //logs signup send event
            sendEventHumioLog(final_datap, "Signup send event")
            //post request for Hubspot
            process.env.SEND_HUBSPOT_DATA === "true" &&
              postRequest(
                process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT,
                final_data,
                function(xhr) {
                  if (xhr.readyState === 4) {
                    handleXHRResult(xhr, token, data)
                  }
                }
              )
            //logs hubspot send event
            sendEventHumioLog(final_data, "Hubspot Lead ")
          })
      })
    } else {
      window.open(
        "https://superops.com/signup",
        "_blank" // <- This is what makes it open in a new window.
      )
    }
  }

  const Template = props => {
    const { data } = props
    return (
      <>
        <Frame seo={data.seo}>
          <Navigation page="Home" />
          <center>
            {param === "goodbyedatto" ||
            (param === "demo" && showVideo && data.youtubeVideoLink) ? (
              <>
                <div className="template-2 mx-auto">
                  {data.headingImage?.url && (
                    <div>
                      <img
                        src={data.headingImage.url}
                        className="w-100 heading-image mb-4"
                      />
                    </div>
                  )}

                  {data.heading && (
                    <h2 className="heading mb-4">{data.heading}</h2>
                  )}

                  {data.content.text && (
                    <div className="content-wrapper mb-4">
                      <p>{data.content.text}</p>
                    </div>
                  )}

                  {data.youtubeVideoLink && (
                    <div className="video-wrapper mb-4">
                      <YoutubePlayer
                        videoURL={data.youtubeVideoLink}
                        videoScreenshot={data.mainImage.url}
                      />
                    </div>
                  )}

                  {data?.secondaryContent && (
                    <div className="cta-text mt-5">
                      {parse(data.secondaryContent.html)}
                    </div>
                  )}

                  {(data?.primaryButtonText || data?.secondaryButtonText) && (
                    <div className="buttons-wrapper mt-5">
                      {data?.primaryButtonText && (
                        <Buttons
                          theme="primary"
                          link={data.primaryButtonLink}
                          text={data.primaryButtonText}
                          arrow
                          onClick={() => {
                            //disables button on single click
                            data.path === "demo" && DemoSignup()
                          }}
                        />
                      )}

                      {data?.ctaText && (
                        <div className="cta-text mt-5">
                          {parse(data.ctaText.html)}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="main mx-auto">
                  <div className="popup-anim-wrapper">
                    <img src={data.mainImage.url} className="w-100 mb-5" />
                  </div>

                  <div className="heading-wrapper mb-4">
                    {data.headingImage?.url && (
                      <span>
                        <img
                          src={data.headingImage.url}
                          className="w-100 heading-image"
                        />
                      </span>
                    )}

                    <h2 className="heading">{data.heading}</h2>
                    {data.headingImage?.url && (
                      <span>
                        <img
                          src={data.headingImage.url}
                          className="w-100 heading-image"
                        />
                      </span>
                    )}
                  </div>

                  {data.content.text && (
                    <div className="content-wrapper mb-4">
                      <p>{data.content.text}</p>
                    </div>
                  )}

                  {(data?.primaryButtonText || data?.secondaryButtonText) && (
                    <div className="buttons-wrapper mt-5 mb-5">
                      {data?.primaryButtonText && data.path === "demo" && (
                        <Buttons
                          theme="primary"
                          onClick={DemoSignup}
                          text={data.primaryButtonText}
                          arrow
                        />
                      )}
                    </div>
                  )}

                  {data.linkButtonText && (
                    <div className="link-button-wrapper">
                      {data.linkButtonLink ? (
                        <>
                          <Link to={data.linkButtonLink}>
                            {data.linkButtonText}
                            <SVGIcon
                              className="arrow-right"
                              name="hypertext-arrow-right"
                            />
                          </Link>
                        </>
                      ) : (
                        <>
                          <a onClick={() => navigate(-1)}>
                            {data.linkButtonText}
                            <SVGIcon
                              className="arrow-right"
                              name="hypertext-arrow-right"
                            />
                          </a>
                        </>
                      )}
                    </div>
                  )}

                  {data?.ctaText && (
                    <div className="cta-text">{parse(data.ctaText.html)}</div>
                  )}
                </div>
              </>
            )}
          </center>
        </Frame>
      </>
    )
  }
  // demo signup end
  return (
    <>
      <StaticQuery
        query={graphql`
          query ThankyouPage {
            SuperOps {
              thankYouPages {
                seo {
                  title
                  description
                  keywords
                  description
                  image {
                    url
                  }
                }
                defaultThankyouPage
                path
                formName
                heading
                ctaText {
                  html
                  text
                }
                content {
                  html
                  text
                }
                secondaryContent {
                  html
                }
                headingImage {
                  url
                }
                mainImage {
                  url
                }
                primaryButtonText
                primaryButtonLink
                secondaryButtonText
                secondaryButtonLink
                linkButtonText
                linkButtonLink
                youtubeVideoLink
                isDemoVideoAvailable
              }
            }
          }
        `}
        render={data => (
          <div className="thankyou-page">
            <RenderIf
              condition={
                data.SuperOps.thankYouPages.filter(el => el.path === param)
                  .length === 0
              }
            >
              {data.SuperOps.thankYouPages
                .filter(el => el.defaultThankyouPage === "Yes")
                .map(pageContent => {
                  return (
                    <>
                      <Template data={pageContent} />
                    </>
                  )
                })}
            </RenderIf>

            <RenderIf
              condition={data.SuperOps.thankYouPages.filter(
                el => el.path === param
              )}
            >
              {data.SuperOps.thankYouPages
                .filter(el =>
                  showVideo
                    ? el.path === param && el.youtubeVideoLink
                    : el.path === param && el.isDemoVideoAvailable !== "Yes"
                )
                .map((pageContent, idx) => {
                  return (
                    <>
                      <Template data={pageContent} />
                    </>
                  )
                })}
            </RenderIf>
          </div>
        )}
      />
    </>
  )
}

export default ThankYou
